<template>
  <LoadingCom v-if="loading" />
  <div class="personInfo" v-else>
    <div class="fixed">
      <div class="back_box">
        <img src="@/assets/img/icon/back.png" alt="" @click.stop="emit('close')" />
        <div class="title">个人资料</div>
      </div>
    </div>
    <div class="container">
      <div class="toping">
        <div class="flex">
          <div class="left_head">
            <img
              :src="filesCdnDomain + conversationInfo.avatar"
              alt=""
              v-if="conversationInfo.avatar"
            />
            <img v-else src="@/assets/img/icon/avart.png" alt="" />

            {{ conversationInfo.nick }}
          </div>
          <div class="follow_btn" v-if="basicUserInfo.isAttented == 0" @click.stop="followBtn">
            <img src="../../assets/img/icon/follow.png" alt="" />关注
          </div>
          <div class="followed_btn" @click.stop="cancleFollow" v-if="basicUserInfo.isAttented == 1">
            已关注
          </div>
        </div>
      </div>
      <div class="toping">
        <div class="info_item height flex" @click="visible = true">
          <div class="left_lable">修改备注</div>
          <div class="right_icon">
            <span>{{ remark ? remark : "备注名" }} </span>
            <img src="@/assets/img/icon/more.png" alt="" class="more_icon" />
          </div>
        </div>
      </div>
      <div class="toping">
        <div class="info_item height flex">
          <div class="left_lable">置顶聊天</div>
          <div class="right_icon">
            <van-switch v-model="isPinned" active-color="#D8AD6D" @change="pinConversation" />
          </div>
        </div>
      </div>
      <div class="bottom_btn">
        <div class="btn_box" @click="clearSheet = true">清空聊天记录</div>
      </div>
    </div>
    <van-action-sheet v-model:show="clearSheet" class="sheet">
      <div class="title">聊天记录删除后不可恢复，是否删除？</div>
      <div class="del_btn btn" @click="handleClearlog">删除</div>
      <div class="btn" @click="clearSheet = false">取消</div>
    </van-action-sheet>
    <van-overlay :show="visible" @click.stop="">
      <PersonRemark
        :name="remark"
        :visible="visible"
        :userID="conversationInfo.userID"
        @close="close"
      />
    </van-overlay>
  </div>
</template>
<script setup name="PersonInfo">
import PersonRemark from "./personRemark.vue";
import { ref, onMounted, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { chat } from "@/untils/tim";
import { useStore } from "vuex";
import { showSuccessToast } from "vant";
import { basicInfo, cancelFollow, addFollow } from "@/assets/api/user";
const store = useStore();
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const router = useRouter();
const loading = ref(true);
const conversationInfo = ref({});
const basicUserInfo = ref({});
const remark = ref("");
const isPinned = ref(false);
const clearSheet = ref(false);
const visible = ref(false);
const emit = defineEmits(["close"]);
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});
function close(v) {
  remark.value = v;
  visible.value = false;
}
function handleClearlog() {
  chat()
    .clearHistoryMessage("C2C" + localStorage.getItem("imChatId"))
    .then((res) => {
      showSuccessToast("已删除");
      clearSheet.value = false;
    });
}
function pinConversation(v) {
  chat()
    .pinConversation({
      conversationID: "C2C" + localStorage.getItem("imChatId"),
      isPinned: v,
    })
    .then((res) => {
      showSuccessToast(v ? "已设置为置顶消息" : "已取消置顶");
    });
}
function _getConversationProfile() {
  let imChatType = localStorage.getItem("imChatType");
  let imChatId = localStorage.getItem("imChatId");
  chat()
    .getConversationProfile(imChatType + imChatId)
    .then((imResponse) => {
      loading.value = false;
      // console.log(imResponse, "会话");
      conversationInfo.value = imResponse.data.conversation.userProfile;
      remark.value = imResponse.data.conversation.remark;
      isPinned.value = imResponse.data.conversation.isPinned;
      _basicInfo(conversationInfo.value.userID * 1);
    })
    .catch(() => {
      loading.value = false;
    });
}
function _basicInfo(id) {
  basicInfo({ platform: 0, userId: id }).then((res) => {
    if (!res.data) return;
    basicUserInfo.value = res.data;
  });
}
function followBtn() {
  addFollow({ uid: conversationInfo.value.userID * 1 }).then((res) => {
    showSuccessToast("关注成功");
    basicUserInfo.value.isAttented = 1;
  });
}
function cancleFollow() {
  cancelFollow({
    uid: conversationInfo.value.userID * 1,
  }).then((res) => {
    showSuccessToast("已取消关注");
    basicUserInfo.value.isAttented = 0;
  });
}

watchEffect(() => {
  if (props.visible) {
    setTimeout(function () {
      _getConversationProfile();
    }, 1000);
  }
});
</script>
<style scoped>
.personInfo {
  min-height: 100vh;
  background: #fafafa;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.container {
  padding: 13.32vw 2.66vw;
}
.bottom_btn {
  background: #fff;
  border-radius: 8px;
  padding: 0 2.66vw;
}
.btn_box {
  height: 12.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ef4444;
  text-align: center;
  font-family: PingFang SC;
  font-size: 3.73vw;
  font-weight: 400;
}

.toping {
  background: #fff;
  border-radius: 8px;
  margin: 0 0 2.66vw 0;
  padding: 0 2.66vw;
}
.left_lable {
  color: #222;
  font-family: PingFang SC;
  font-size: 3.73vw;
  font-weight: 400;
}
.right_icon {
  display: flex;
  align-items: center;
}
span {
  color: rgba(0, 0, 0, 0.45);
  font-family: PingFang SC;
  font-size: 3.73vw;
  font-weight: 400;
}
.group_head {
  width: 10.13vw;
  height: 10.13vw;
  border-radius: 50%;
}

.left_head {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222;
  font-family: PingFang SC;
  font-size: 4.26vw;
  font-weight: 400;
  margin: 2.66vw 0;
}
img {
  width: 12.8vw;
  height: 12.8vw;
  border-radius: 50%;
  margin-right: 2.66vw;
  object-fit: cover;
}

.follow_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  border-radius: 6px;
  background: #d8ad6d;
  text-align: center;
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-weight: 600;
  width: 14.93vw;
  height: 6.93vw;
}
.follow_btn img {
  width: 3.2vw;
  height: 3.2vw;
}
.followed_btn {
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-style: normal;
  font-weight: 600;
  line-height: 3.73vw;
  border-radius: 6px;
  padding: 6px 10px;
  border: 0.5px solid #d1d5db;
  background: #fff;
}
.height {
  height: 12.8vw;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.more_icon {
  width: 5.33vw;
  height: 5.33vw;
}

.sheet div {
  padding: 5.33vw 0;
}

.title {
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  font-family: PingFang SC;
  font-size: 3.73vw;
  font-weight: 400;
}
.del_btn {
  border-bottom: 8px solid #fafafa;
}
.btn {
  color: #222;
  text-align: center;
  font-family: PingFang SC;
  font-size: 4.26vw;
  font-weight: 400;
}
.van-loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
